<script>
import { defineComponent, ref } from 'vue'

import saveQueryModal from "./modal/saveQueryModal.vue";
import confirmDeleteQueryModal from "./modal/confirmDeleteQueryModal.vue";

export default defineComponent({
  name: 'EntityStorage',
  components: {
    saveQueryModal,
    confirmDeleteQueryModal
  },
  props: {
    filters: {
      type: Object,
      required: true
    },
    setQuery: {
      type: Function,
      required: true
    },
    doSearch: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      requestSource: null,
      openLoadQuerySubmenu: false,
      showSaveQueryModal: false,
      showConfirmQueryDeleteModal: false,
      queryMenuDropdownState: ref(false),
      queryToDelete: null
    }
  },

  methods: {
    getQueries() {
      let u = JSON.parse(localStorage.getItem("user"));
      return u.user_preference?.queries;
    },

    async openSaveQueryModal() {
      this.queryMenuDropdownState = false;
      this.openLoadQuerySubmenu = false;
      this.showSaveQueryModal = true;
    },

    async openConfirmQueryDeleteModal(queryName) {
      this.queryMenuDropdownState = false;
      this.queryToDelete = queryName;
      this.openLoadQuerySubmenu = false;
      this.showConfirmQueryDeleteModal = true;
    },

    saveQuery(name, query) {
      this.showSaveQueryModal = false;
      this.openLoadQuerySubmenu = false;
      let u = JSON.parse(localStorage.getItem("user"));
      let index = -1;
      if(typeof u.user_preference.queries === "undefined") {
        u.user_preference.queries = [];
      } else {
        index = u.user_preference.queries.findIndex(query => query.name === name); // Returns -1 if nothing found
      }
      if (index === -1) {
        u.user_preference.queries.push({name, query});
      } else {
        u.user_preference.queries[index] = {name, query};
      }
      localStorage.setItem("user", JSON.stringify(u));
      this.filters.queryModel = query;
      this.setQuery(query); // Overwrite in case user this some edit in the modal
      this.$store.dispatch('alert/success', 'entities.modal.saveQuerySuccess');
      this.getQueries();
    },

    loadQuery(query) {
      this.showSaveQueryModal = false;
      this.openLoadQuerySubmenu = false;
      this.setQuery(query.query);
      this.doSearch();
      this.$store.dispatch('alert/success', 'entities.modal.loadQuerySuccess');
      this.getQueries();
    },

    deleteQuery(queryToDelete) {
      this.showConfirmQueryDeleteModal = false;
      this.openLoadQuerySubmenu = false;
      let u = JSON.parse(localStorage.getItem("user"));
      let index = u.user_preference.queries.findIndex(query => query.name === queryToDelete);
      u.user_preference.queries.splice(index, 1);
      localStorage.setItem("user", JSON.stringify(u));
      this.$store.dispatch('alert/success', 'entities.modal.deleteQuerySuccess');
      this.getQueries();
    }
  }
})
</script>

<template>
  <q-btn-dropdown v-model="queryMenuDropdownState" class="q-ml-sm app-action-btn" label="Queries" flat dense style="padding: .35rem .5rem">
    <q-list>
      <q-item clickable v-ripple>
        <q-item-section @click.capture.stop="openSaveQueryModal">{{ $t("entities.saveQuery") }}</q-item-section>
      </q-item>
      <q-item clickable v-ripple
              @click.capture.stop="openLoadQuerySubmenu = !openLoadQuerySubmenu"
              @mouseover="openLoadQuerySubmenu = true"
      >
        <q-item-section :class="{ 'disabled': !getQueries() || getQueries()?.length === 0}">{{ $t("entities.loadQuery") }}</q-item-section>
        <template v-if="getQueries()">
          <q-item-section v-if="getQueries().length > 0" side>
            <q-icon name="arrow_right" />
          </q-item-section>
          <q-menu v-model="openLoadQuerySubmenu" anchor="top right" self="top left">
            <q-list @mouseleave="openLoadQuerySubmenu = false">
              <q-item v-for="query in getQueries()" clickable v-ripple>
                <q-item-section @click.capture.stop="loadQuery(query)">{{ query.name }}</q-item-section>
                <q-item-section side>
                  <q-btn flat dense
                         color="negative"
                         icon="delete"
                         @click.stop="openConfirmQueryDeleteModal(query.name)"
                         :title="$t('entities.deleteQuery')"
                  />
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </template>
      </q-item>
    </q-list>
  </q-btn-dropdown>


  <save-query-modal
    v-if="showSaveQueryModal"
    v-model="showSaveQueryModal"
    :query="filters.queryModel"
    :storage="getQueries()"
    @confirm="saveQuery"
  />

  <confirm-delete-query-modal
    v-if="showConfirmQueryDeleteModal"
    v-model="showConfirmQueryDeleteModal"
    :name="queryToDelete"
    @confirm="deleteQuery"
  />
</template>
